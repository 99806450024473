<template>
  <div id="user-form">
    <p v-if="errors.length">
      <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
      <ul>
        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
      </ul>
    </p>
    <h3 v-if="!esRegistroUsuario"> Datos Solicitante</h3>
    <div class="form-group">
      <label for="nombre">Nombre</label>
      <input type="text" class="form-control" id="nombre" placeholder="Nombre"  v-model="$v.usuario.nombre.$model"
        :class="status($v.usuario.nombre)">
    </div>
    <div class="form-group">
      <label for="apellido">Apellido</label>
      <input type="text" class="form-control" id="apellido" placeholder="Apellido" v-model="$v.usuario.apellido.$model"
        :class="status($v.usuario.apellido)">
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input
        type="email"
        class="form-control"
        id="email"
        aria-describedby="emailHelp"
        placeholder="Ingrese Email"
        v-model="$v.usuario.email.$model"
        :class="status($v.usuario.email)"
      />
    </div>
    <div class="form-group">
      <label for="telefono">Teléfono</label>
      <input type="number" class="form-control" id="telefono" placeholder="Teléfono" v-model="$v.usuario.telefono.$model"
        :class="status($v.usuario.telefono)">
    </div>

    <h3 v-if="!esRegistroUsuario"> Datos de envío</h3>
    <div class="form-group">
      <label for="calle">Calle</label>
      <input type="text" class="form-control" id="calle" placeholder="Calle" v-model="$v.usuario.calle.$model"
        :class="status($v.usuario.calle)">
    </div>
    <div class="form-group">
      <label for="numero">Número</label>
      <input type="number" class="form-control" id="numero" placeholder="Número" v-model="$v.usuario.numero.$model"
        :class="status($v.usuario.numero)">
    </div>
    <div class="form-group">
      <label for="departamento">Departamento</label>
      <input type="text" class="form-control" id="departamento" placeholder="Departamento" v-model="$v.usuario.departamento.$model"
        :class="status($v.usuario.departamento)">
    </div>
    <div v-if="!esRegistroUsuario"  class="form-group">
      <label for="referencia">Referencia (Opcional)</label>
      <input type="text" class="form-control" id="referencia" placeholder="Referencia" v-model="$v.usuario.referencia.$model"
        :class="status($v.usuario.referencia)">
    </div>
    <div class="form-group">
      <label for="comuna">Comuna</label>
      <input type="text" class="form-control" id="comuna" placeholder="Comuna" v-model="$v.usuario.comuna.$model"
        :class="status($v.usuario.comuna)">
    </div>
    <div class="form-group">
      <label for="region">Región</label>
      <input type="text" class="form-control" id="region" placeholder="Región" v-model="$v.usuario.region.$model"
        :class="status($v.usuario.region)">
    </div>
     <div v-if="!esRegistroUsuario" class="form-group">
      <label for="rut">RUT (Sólo para envíos por Starken)</label>
      <input type="text" class="form-control" id="rut" placeholder="ej 12345678-9" v-model="$v.usuario.rut.$model"
        :class="status($v.usuario.rut)">
    </div>
    <div v-if="esRegistroUsuario" class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <input
        type="password"
        class="form-control"
        id="password"
        placeholder="Password"
        v-model="$v.usuario.password.$model"
        :class="status($v.usuario.password)"
      />
    </div>
    <div v-if="esRegistroUsuario" class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <input
        type="password"
        class="form-control"
        id="rePassword"
        placeholder="Repetir Password"
        v-model="$v.usuario.rePassword.$model"
        :class="status($v.usuario.rePassword)"
      />
    </div>
    <button v-if="esRegistroUsuario" @click="registrarUsuario()" :disabled="btnDisabled" class="btn btn-primary float-right">Crear Cuenta</button>
    <button v-else @click="continuar()" :disabled="btnDisabled" class="btn btn-primary float-right">Continuar</button>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import { required, minLength, maxLength, email, sameAs, numeric } from 'vuelidate/lib/validators'
import { store } from '@/store/storeBuscador'

export default {
  name: 'FormularioUsuario',
  beforeCreate () {
    this.$store.state.modoCompra = true
  },
  beforeDestroy () {
    this.$store.state.modoCompra = false
  },
  data () {
    return {
      usuario: {
        email: '',
        nombre: '',
        apellido: '',
        calle: '',
        numero: '',
        departamento: '',
        comuna: '',
        region: '',
        telefono: '',
        password: '',
        rePassword: '',
        rut: '',
        referencia: ''
      },
      errors: [],
      btnDisabled: false
    }
  },
  validations: {
    usuario: {
      email: {
        required,
        email,
        minLength: minLength(6),
        maxLength: maxLength(100)
      },
      nombre: {
        required,
        maxLength: maxLength(50)
      },
      apellido: {
        required,
        maxLength: maxLength(50)
      },
      calle: {
        required,
        maxLength: maxLength(100)
      },
      numero: {
        required,
        numeric,
        maxLength: maxLength(25)
      },
      departamento: {
        maxLength: maxLength(25)
      },
      comuna: {
        required,
        maxLength: maxLength(150)
      },
      region: {
        required,
        maxLength: maxLength(150)
      },
      telefono: {
        required,
        maxLength: maxLength(12),
        numeric
      },
      password: {
        required,
        minLength: minLength(6)
      },
      rePassword: {
        sameAsPassword: sameAs('password')
      },
      rut: {
        maxLength: maxLength(10),
        minLength: minLength(9)
      },
      referencia: {
        maxLength: maxLength(150)
      }

    }
  },
  mounted () {
    if (this.loggedIn && this.esRegistroUsuario) {
      this.$router.push('/')
    }
  },
  methods: {
    ...mapMutations(['comprar']),
    continuar () {
      this.btnDisabled = true
      if (this.check()) {
        this.comprar(this.usuario)
      } else {
        this.btnDisabled = false
      }
    },
    registrarUsuario () {
      this.btnDisabled = true
      if (this.check()) {
        try {
          const reqUsuario = {}
          reqUsuario.email = this.usuario.email
          reqUsuario.nombre = this.usuario.nombre
          reqUsuario.apellido = this.usuario.apellido
          reqUsuario.direccion = this.usuario.calle + ' ' + this.usuario.numero
          if (this.usuario.departamento) {
            reqUsuario.direccion = reqUsuario.direccion + ' ' + this.usuario.departamento
          }
          reqUsuario.comuna = this.usuario.comuna
          reqUsuario.region = this.usuario.region
          reqUsuario.telefono = this.usuario.telefono
          reqUsuario.password = this.usuario.password
          reqUsuario.idRol = 2
          this.$store.dispatch('auth/register', reqUsuario)
            .then(() => {
              this.$router.push({ name: 'Login' })
            })
            .catch(() => {
              this.$router.push({ name: 'Error' })
            })
        } catch (error) {
          this.$router.push({ name: 'Error' })
        }
      } else {
        this.btnDisabled = false
      }
    },
    check () {
      this.errors = []
      this.$v.$touch()
      if (!this.$v.usuario.email.required) {
        this.errors.push('Debe ingresar un email')
      } else if (!this.$v.usuario.email.minLength) {
        this.errors.push('El email debe tener como mínimo 6 caracteres')
      } else if (!this.$v.usuario.email.maxLength) {
        this.errors.push('El email debe tener como máximo 100 caracteres')
      } else if (!this.$v.usuario.email.email) {
        this.errors.push('Ingrese un email es válido')
      }

      if (!this.$v.usuario.nombre.required) {
        this.errors.push('Debe ingresar un nombre')
      } else if (!this.$v.usuario.nombre.maxLength) {
        this.errors.push('El nombre debe tener como máximo 50 caracteres')
      }

      if (!this.$v.usuario.apellido.required) {
        this.errors.push('Debe ingresar un apellido')
      } else if (!this.$v.usuario.nombre.maxLength) {
        this.errors.push('El apellido debe tener como máximo 50 caracteres')
      }

      if (!this.$v.usuario.calle.required) {
        this.errors.push('Debe ingresar una calle')
      } else if (!this.$v.usuario.calle.maxLength) {
        this.errors.push('La calle debe tener como máximo 100 caracteres')
      }

      if (!this.$v.usuario.numero.required) {
        this.errors.push('Debe ingresar una numero')
      } else if (!this.$v.usuario.numero.maxLength) {
        this.errors.push('La numero debe tener como máximo 25 caracteres')
      }

      if (!this.$v.usuario.departamento.maxLength) {
        this.errors.push('La departamento debe tener como máximo 25 caracteres')
      }

      if (!this.$v.usuario.comuna.required) {
        this.errors.push('Debe ingresar una comuna')
      } else if (!this.$v.usuario.comuna.maxLength) {
        this.errors.push('La comuna debe tener como máximo 150 caracteres')
      }

      if (!this.$v.usuario.region.required) {
        this.errors.push('Debe ingresar una región')
      } else if (!this.$v.usuario.region.maxLength) {
        this.errors.push('La región debe tener como máximo 150 caracteres')
      }

      if (!this.$v.usuario.telefono.required) {
        this.errors.push('Debe ingresar un telefono')
      } else if (!this.$v.usuario.telefono.maxLength) {
        this.errors.push('El telefono debe tener como máximo 12 caracteres')
      } else if (!this.$v.usuario.telefono.numeric) {
        this.errors.push('El telefono debe contener solo numeros')
      }
      if (this.esRegistroUsuario) {
        if (!this.$v.usuario.password.required) {
          this.errors.push('Debe ingresar una password')
        } else if (!this.$v.usuario.password.minLength) {
          this.errors.push('Su password debe contener como mínimo 6 caracteres')
        }
        if (!this.$v.usuario.rePassword.sameAsPassword) {
          this.errors.push('Debe repetir su password de forma correcta')
        }
      } else {
        if (!this.$v.usuario.referencia.maxLength) {
          this.errors.push('La referencia debe tener como máximo 150 caracteres')
        }
        var envioStarken = 4
        var envioEsStarken = this.carro.envio.id === envioStarken
        if (envioEsStarken && !this.usuario.rut) {
          this.errors.push('El rut es obligatorio para envios mediante STARKEN')
        } else if (envioEsStarken && !this.validaRut(this.usuario.rut)) {
          this.errors.push('Ingrese un rut válido')
        }

        if (!this.$v.usuario.rut.maxLength) {
          this.errors.push('el rut debe tener como máximo 10 caracteres')
        } else if (!this.$v.usuario.rut.minLength) {
          this.errors.push('El rut debe tener como mínimo 9 caracteres')
        }
      }

      if (this.errors.length) {
        return false
      } else {
        return true
      }
    },
    validaRut (rutCompleto) {
      rutCompleto = rutCompleto.replace('‐', '-')
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) {
        return false
      }
      var tmp = rutCompleto.split('-')
      var digv = tmp[1]
      var rut = tmp[0]
      if (digv === 'K') {
        digv = 'k'
      }
      return this.dv(rut) === digv
    },
    dv (T) {
      var M = 0
      var S = 1
      for (; T; T = Math.floor(T / 10)) {
        S = (S + T % 10 * (9 - M++ % 6)) % 11
      }
      return S ? (S - 1).toString() : 'k'
    },
    status (validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.name === 'RegistrarCuenta') {
      store.state.esRegistroUsuario = true
      next()
    } else if (to.name === 'FormularioUsuario') {
      store.state.esRegistroUsuario = false
      next()
    }
  },
  computed: {
    ...mapState({
      esRegistroUsuario: state => state.esRegistroUsuario,
      loggedIn: state => state.auth.status.loggedIn,
      carro: state => state.carro
    })
  }
}
</script>
<style scoped>
#user-form {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
  margin-left: 6.5rem !important;
  margin-right: 6.5rem !important;
}

@media screen and (max-width: 500px) {
  #user-form {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
}
</style>
