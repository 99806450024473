<template>
    <h3>Por el momento no podemos atender su solicitud</h3>
</template>
<script>

export default {
  name: 'Error',
  beforeMount () {
    this.$store.state.modoCompra = true
  },
  beforeDestroy () {
    this.$store.state.modoCompra = false
  }
}
</script>
