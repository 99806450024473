<template>
  <div class="container-fluid mt-4">
    <table class="table" ref="table">
      <thead>
        <tr>
          <th v-for="(columnas, index) in fields"  v-bind:key="'column'+index" >{{columnas}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(producto, index) in carro.listProductos"  v-bind:key="'producto'+index">
          <td>
            <b-icon icon="trash" aria-hidden="true" scale="1" @click="removerItem(producto)"></b-icon>
            <b-icon icon="cart-plus-fill" aria-hidden="true" scale="1" @click="sumarItem(producto)"></b-icon>
            <b-icon icon="cart-dash-fill" aria-hidden="true" scale="1" @click="restarItem(producto)"></b-icon>
          </td>
          <td><img :src="producto.imagen" style="width: 50px; max-width: 100%;"></td>
          <td>{{producto.glosa}} ({{producto.condicion}})</td>
          <td>{{producto.cantidad}}</td>
          <td>{{producto.precioFormat}}</td>
        </tr>
        <tr v-if="carro.cantidadProductos > 0">
          <td></td>
          <td></td>
          <td>
            <select class="form-control" id="tipoEnvio" @change="cambioSelectEnvio($event)" v-model="idEnvio">
              <option value="-1">Seleccione Tipo Envío</option>
              <option v-for="envioSelect in envios" v-bind:key="'select'+envioSelect.id" :value="envioSelect.id">{{envioSelect.glosa}} - {{envioSelect.precioFormat}}</option>
            </select>
          </td>
          <td>Total</td>
          <td>{{carro.precioTotalFormat}}</td>
        </tr>
      </tbody>
    </table>
    <p class="text-center" v-if="carro.cantidadProductos == 0">Tu Carro de compras está vacío</p>
    <button v-if="carro.cantidadProductos > 0" @click="continuar()" type="button" class="btn btn-primary float-right">Continuar</button>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import axios from 'axios'

export default {
  name: 'Checkout',
  data () {
    return {
      fields: ['remover', 'imagen', 'producto', 'cantidad', 'precio'],
      envios: [],
      idEnvio: -1
    }
  },
  methods: {
    ...mapActions(['removerPorId', 'sumarProductoPorId', 'restarProductoPorId']),
    ...mapMutations(['setEnvio']),
    removerItem (producto) {
      this.removerPorId(producto.id)
    },
    sumarItem (producto) {
      this.sumarProductoPorId(producto.id)
    },
    restarItem (producto) {
      this.restarProductoPorId(producto.id)
    },
    continuar () {
      if (this.idEnvio === -1) {
        const newEnvio = {}
        newEnvio.id = -1
        newEnvio.precio = 0
        this.setEnvio(newEnvio)
        alert('Debe seleccionar tipo de envio')
        return
      }

      this.$router.push({ name: 'FormularioUsuario' })
    },
    async getEnvios () {
      const url = '/envios'
      try {
        const resp = await axios.get(url, {
          baseURL: process.env.VUE_APP_URL_API_BESTTUTOR
        })
        this.envios = await resp.data
      } catch (error) {
        this.$router.push({ name: 'Error' })
      }
    },
    cambioSelectEnvio (event) {
      const idEnvio = Number(event.target.value)
      if (idEnvio > 0) {
        for (const envio of this.envios) {
          if (idEnvio === envio.id) {
            this.setEnvio(envio)
          }
        }
      } else {
        const newEnvio = {}
        newEnvio.id = -1
        newEnvio.precio = 0
        this.setEnvio(newEnvio)
        alert('Debe seleccionar tipo de envio')
      }
    }
  },
  async beforeMount () {
    await this.getEnvios()
  },
  mounted () {
    this.idEnvio = this.carro.envio.id
  },
  computed: {
    ...mapState({
      carro: state => state.carro
    })
  }
}
</script>
