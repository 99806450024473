import axios from 'axios'
import authHeader from './auth-header.service'

class PedidoService {
  obtenerPedidos () {
    const url = 'pedidos'
    return axios.get(url, {
      baseURL: process.env.VUE_APP_URL_API_BESTTUTOR,
      headers: authHeader()
    })
  }

  obtenerPedidosPorEmail (email) {
    const url = 'pedidos/' + email
    return axios.get(url, {
      baseURL: process.env.VUE_APP_URL_API_BESTTUTOR,
      headers: authHeader()
    })
  }
}
export default new PedidoService()
