import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Checkout from '../views/Checkout.vue'
import FormularioUsuario from '../views/FormularioUsuario.vue'
import PasoFinalCompra from '../views/PasoFinalCompra.vue'
import Store from '../views/Store.vue'
import ErrorStore from '../views/Error.vue'
import FAQ from '../views/FAQ.vue'
import Login from '../views/Login.vue'
import Pedidos from '../views/Pedidos.vue'
import Utilitarios from '../views/Utilitarios.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Store',
    component: Store,
    meta: {
      title: 'Best Tutor'
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
          title: 'Best Tutor'
        }
      },
      {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta: {
          title: 'Checkout'
        }
      },
      {
        path: '/pedidos',
        name: 'Pedidos',
        component: Pedidos,
        meta: {
          title: 'Pedidos'
        }
      },
      {
        path: '/utilitarios',
        name: 'Utilitarios',
        component: Utilitarios,
        meta: {
          title: 'Utilitarios'
        }
      },
      {
        path: '/formularioUsuario',
        name: 'FormularioUsuario',
        component: FormularioUsuario,
        meta: {
          title: 'Formulario'
        }
      },
      {
        path: '/registrarCuenta',
        name: 'RegistrarCuenta',
        component: FormularioUsuario,
        meta: {
          title: 'Formulario'
        }
      },
      {
        path: '/final',
        name: 'PasoFinalCompra',
        component: PasoFinalCompra,
        meta: {
          title: 'Gracias!'
        }
      },
      {
        path: '/faq',
        name: 'FAQ',
        component: FAQ,
        meta: {
          title: 'FAQ'
        }
      },
      {
        path: '/error',
        name: 'Error',
        component: ErrorStore,
        meta: {
          title: 'Error!'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
