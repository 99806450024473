import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuelidate from 'vuelidate'

import { store } from '@/store/storeBuscador'
import axios from 'axios'
import VueAxios from 'vue-axios'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Vuelidate)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  mounted () {
    document.title = 'Best Tutor'
    axios.interceptors.response.use(
      (response) => {
        if (response.status === 401) {
          this.$store.dispatch('auth/logout', this.usuario)
            .then(() => {
              this.$router.push({ name: 'Home' })
            })
            .catch(() => {
              this.$router.push({ name: 'Error' })
            })
        }
        return response
      },
      (error) => {
        if (error.response) {
          if (error.response.status && error.response.status === 401) {
            this.$store.dispatch('auth/logout', this.usuario)
              .then(() => {
                this.$router.push({ name: 'Home' })
              })
              .catch(() => {
                this.$router.push({ name: 'Error' })
              })
          }
          return Promise.reject(error.response.data)
        }
        return Promise.reject(error.message)
      })
  },
  validations: {},
  render: h => h(App)
}).$mount('#app')
