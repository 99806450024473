<template>
  <div @click="addProducto(carta, tipo)">
    <b-icon v-if="isActive"  icon="cart-plus-fill" aria-hidden="true" scale="2" ></b-icon>
    <b-icon v-else icon="cart-plus" aria-hidden="true" scale="2" ></b-icon>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
export default {
  name: 'CarroBoton',
  data () {
    return {
      isActive: true
    }
  },
  props: ['carta', 'tipo'],
  methods: {
    ...mapMutations(['setCarro']),
    cambiarEstado () {
      this.isActive = false
      setTimeout(() => { this.isActive = true }, 300)
    },
    addProducto (carta, tipo) {
      this.cambiarEstado()
      const item = {}
      item.id = tipo.id
      item.glosa = `${carta.nombre}-${carta.edicion}-${carta.idioma}`
      item.nombre = carta.nombre
      item.edicion = carta.edicion
      item.idioma = carta.idioma
      item.condicion = tipo.condicion
      item.idCondicion = tipo.idCondicion
      item.precio = tipo.precio
      item.cantidad = 1
      item.imagen = carta.imagen
      item.stock = tipo.cantidad
      this.setCarro(item)
    }
  },
  computed: {
    ...mapState({
      cartas: state => state.cartas
    })
  }
}
</script>
