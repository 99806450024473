<template>
    <div class="wrapper fadeInDown">
      <div id="formContent">
        <!-- Tabs Titles -->
        <!-- Icon -->
        <div class="fadeIn first">
          <router-link to="/">
            <img src="../assets/images/logo-besttutor-1.png" id="icon" alt="User Icon" />
          </router-link>
        </div>
        <!-- Login Form -->
        <form>
          <p>{{strError}}</p>
          <input type="text" id="email" class="fadeIn second" name="email" placeholder="email" v-model="$v.usuario.email.$model"
        :class="status($v.usuario.email)">
          <input type="password" id="password" autocomplete="on" class="fadeIn third" name="password" placeholder="password" v-model="$v.usuario.password.$model"
        :class="status($v.usuario.password)">
          <input type="button" @click="login()" :disabled="btnDisabled" class="fadeIn fourth" value="Log In"/>
        </form>
        <!-- Remind Passowrd -->
        <!-- <div id="formFooter">
          <a class="underlineHover" href="#">Olvido su password?</a>
        </div> -->
      </div>
    </div>
</template>
<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  data () {
    return {
      usuario: {
        email: '',
        password: ''
      },
      btnDisabled: false,
      strError: ''
    }
  },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title
    }
  },
  validations: {
    usuario: {
      email: {
        required,
        email,
        minLength: minLength(6),
        maxLength: maxLength(100)
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  created () {
    if (this.loggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    login () {
      this.strError = ''
      this.btnDisabled = true
      if (this.check()) {
        try {
          const reqUsuario = {}
          reqUsuario.email = this.usuario.email
          reqUsuario.password = this.usuario.password
          this.$store.dispatch('auth/login', this.usuario)
            .then(() => {
              this.$router.push({ name: 'Home' })
            })
            .catch(() => {
              this.btnDisabled = false
              this.strError = 'Error al realizar login'
            })
        } catch (error) {
          this.btnDisabled = false
          this.strError = 'Error al realizar login'
        }
      } else {
        this.btnDisabled = false
        this.strError = 'Error al realizar login'
      }
    },
    check () {
      this.errors = []
      this.$v.$touch()
      if (!this.$v.usuario.email.required) {
        this.errors.push('Debe ingresar un email')
      } else if (!this.$v.usuario.email.minLength) {
        this.errors.push('El email debe tener como mínimo 6 caracteres')
      } else if (!this.$v.usuario.email.maxLength) {
        this.errors.push('El email debe tener como máximo 100 caracteres')
      } else if (!this.$v.usuario.email.email) {
        this.errors.push('Ingrese un email es válido')
      }

      if (!this.$v.usuario.password.required) {
        this.errors.push('Debe ingresar una password')
      } else if (!this.$v.usuario.password.minLength) {
        this.errors.push('Su password debe contener como mínimo 6 caracteres')
      }

      if (this.errors.length) {
        return false
      } else {
        return true
      }
    },
    status (validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    }
  }
}
</script>

<style scoped>
html {
  background-color: #56baed;
}
body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}
a {
  color: #92badd;
  display:inline-block;
  text-decoration: none;
  font-weight: 400;
}
h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}
/* STRUCTURE */
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}
#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}
#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
/* TABS */
h2.inactive {
  color: #cccccc;
}
h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}
/* FORM TYPOGRAPHY*/
input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
  background-color: #39ace7;
}
input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}
input[type=text], input[type=password] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
input[type=text]:focus, input[type=password]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}
input[type=text]:placeholder, input[type=password]:placeholder {
  color: #cccccc;
}
/* ANIMATIONS */
/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}
.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}
/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}
.underlineHover:hover {
  color: #0d0d0d;
}
.underlineHover:hover:after{
  width: 100%;
}
/* OTHERS */
*:focus {
  outline: none;
}
#icon {
  width:60%;
}
</style>
