<template>
  <div>
    <b-navbar fixed="top" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand>
        <router-link to="/">
          <img
              alt="logo"
              src="../assets/images/logo-besttutor.png"
              class="logo"
          />
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" style="color: white !important"><router-link to="/FAQ"> <b-icon class="nav-icon-tutor" icon="question-circle-fill" aria-label="Help"></b-icon>FAQ</router-link></b-nav-item>
          <b-nav-item href="https://www.instagram.com/best_tutor_chile/" target="_blank"
            ><img
              alt="instagram"
              src="../assets/images/instagram.png"
              class="red-social"
          /></b-nav-item>
          <b-nav-item href="https://www.facebook.com/best.tutor.378" target="_blank"
            ><img
              alt="facebook"
              src="../assets/images/facebook.png"
              class="red-social"
          /></b-nav-item>
          <b-nav-item href="https://wa.me/56965004096" target="_blank"
            ><img
              alt="whatsapp"
              src="../assets/images/whatsapp.png"
              class="red-social"
          /></b-nav-item>
          <b-nav-item href="mailto:  Contacto@besttutor.cl" target="_blank"
            ><img
              alt="facebook"
              src="../assets/images/gmail.png"
              class="red-social"
          /></b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav v-if="!modoCompra">
            <Buscador/>
          </b-navbar-nav>
          <b-nav-item>
            <router-link to="checkout">

              <button v-if="carroActivo" class="btn-cart btn">
                <b-icon icon="cart" aria-hidden="true" scale="1"></b-icon
                ><span class="cont-item">{{items}}</span>
              </button>
            </router-link>
            </b-nav-item
          >

          <b-nav-item-dropdown right>
            <template #button-content>
              <button class="btn-cart btn">
                <b-icon icon="person-fill"></b-icon>
              </button>
            </template>
            <b-dropdown-item v-if="loggedIn" >{{usuario.nombre}} {{usuario.apellido}}</b-dropdown-item>
            <b-dropdown-item v-if="loggedIn" @click="irAPedidos()">Pedidos</b-dropdown-item>
            <b-dropdown-item v-if="loggedIn && usuario.rolId === 1" @click="irAUtilitarios()">Utilitario</b-dropdown-item>
            <b-dropdown-item v-if="loggedIn" @click.prevent="logOut" href="#">Logout</b-dropdown-item>
            <b-dropdown-item v-if="!loggedIn" @click="irALogin()">Login</b-dropdown-item>
            <b-dropdown-item v-if="!loggedIn" @click="irARegistrarCuenta()">Registrar Cuenta</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
//  import { store } from '@/store/storeBuscador'
import { mapState, mapMutations } from 'vuex'
import Buscador from '@/components/Buscador.vue'

export default {
  name: 'Cabecera',
  //  store,
  components: {
    Buscador
  },
  computed: {
    ...mapState({
      items: state => state.carro.cantidadProductos,
      modoCompra: state => state.modoCompra,
      usuario: state => state.auth.usuario,
      carroActivo: state => state.carroActivo
    }),
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  async beforeMount () {
    const resp = this.carroEsActivo()
    console.log('resp ' + resp)
  },
  methods: {
    ...mapMutations(['carroEsActivo']),
    logOut () {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    irAPedidos () {
      this.$router.push('/pedidos')
    },
    irAUtilitarios () {
      this.$router.push('/utilitarios')
    },
    irALogin () {
      this.$router.push('/login')
    },
    irARegistrarCuenta () {
      this.$router.push('/RegistrarCuenta')
    }
  }
}
</script>
<style scoped>
.red-social {
  height: 21px;
  width: auto;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #fff !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
.btn-cart {
  background-color: white;
  border-radius: 4px;
  font-size: 17px;
  color: black;
  min-width: 67px;
  position: relative;
}
.cont-item {
  background-color: #fff;
  border: 2px solid #212529;
  border-radius: 50%;
  color: #212529;
  height: 27px;
  position: absolute;
  right: -9.33333px;
  top: -9.33333px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  width: 31px;
  font-size: 14px;
  font-weight: bold;
}
.nav-icon-tuto{
  margin-right: 4px;
}
.car{
  display: block;
}
.logo{
  width:150px;
}
a {
  color: #fff !important;
}
.dropdown-menu li a a{
  color: #000 !important;
}
</style>
