<template>
  <div>
    <div>
      <div class="d-flex">
        <b-form-select class="ml-auto p-2 m-2"  v-model="tipoBusqueda" :options="opcionBusqueda"></b-form-select>
        <input
          class="form-control p-2 m-2"
          type="search"
          placeholder="Buscar Carta"
          aria-label="Search"
          :value="textoBuscarInput"
          @input="setTextoBuscar"
          @keyup.enter="buscar()"
        />
        <button
          class="btn btn-cart ml-auto p-2 m-2"
          @click="buscar()"
        >
          <b-icon icon="search"></b-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '@/store/storeBuscador'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'Buscador',
  data () {
    return {
      tipoBusqueda: 1
    }
  },
  store,
  methods: {
    ...mapMutations(['setPaginador']),
    ...mapActions(['obtenerCartas']),
    setTextoBuscar (e) {
      this.$store.commit('setTextoBuscar', e.target.value)
    },
    setTipoBusqueda () {
      this.$store.commit('setTipoBusqueda', this.tipoBusqueda)
    },
    buscar () {
      this.setTipoBusqueda()
      this.setPaginador()
      this.obtenerCartas()
      // validar si estoy en el home TODO
      if (this.$route.path !== '/') {
        this.$router.push({ name: 'Home' })
      }
    }
  },
  computed: {
    ...mapState({
      textoBuscarInput: state => state.textoBuscarInput,
      opcionBusqueda: state => state.opcionBusqueda,
      paginador: state => state.paginador,
      cartas: state => state.cartas
    })
  }
}
</script>
<style scoped>
.btn-cart {
  background-color: white;
  border-radius: 4px;
  font-size: 15px;
  color: black;
  min-width: 67px;
  position: relative;
}
</style>
