<template>
  <div>
    <h1>Preguntas Frecuentes</h1>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="secondary">¿Qué significan los niveles de stock?</b-button>
        </b-card-header>
        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>Los niveles de stock indican la cantidad que maneja nuestro proveedor, esto puede ser bajo, medio o alto. </b-card-text>
            <b-card-text><code>Bajo:</code> significa que la cantidad de stock es de unas pocas unidades, con una baja probabilidad de conseguir la carta el día de confirmación, pero no imposible, así que en si quieres tratar no hay problema.</b-card-text>
            <b-card-text><code>Medio:</code> Por lo general con nivel medio, ya es posible encontrar stock al momento de medir o incluso Playset. Así que estás cartas puedes pedir con confianza.</b-card-text>
            <b-card-text><code>Alto:</code> Con niveles alto de stock puedes pedir con gran confianza. Lo más probable que puedas pedir el playset o más sin problemas.</b-card-text>
            <b-card-text>Recuerda que independiente el nivel de stock, siempre está la posibilidad de que al momento de confirmar no encontremos stock, pero despreocúpate. En esos casos te regresamos el dinero sin retención de algún tipo.</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-2 variant="secondary">¿Si pago la carta, me aseguro de recibirla?</b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>Aunque nuestro proveedor maneja un gran stock de cartas, hay veces que se agota antes de poder realizar la confirmación. Y aunque estos casos son raros, es posible, en ese caso se informa al cliente y se realiza la devolución total de la carta al día siguiente.</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-3 variant="secondary">¿A qué comunas realizan envíos?</b-button>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ text }}</b-card-text>
            <b-card-text> La empresa que realiza estos envíos nos ofrece 3 tipos de envío desentendiendo de la comuna a repartir.</b-card-text>
            <b-card-text> <code>Reparto $2.500</code>
Quilicura, Huechuraba, Renca, Conchalí, Independencia, Recoleta, Cerro Navia, Quinta Normal, Santiago Centro, Cerrillos, Pedro Aguirre Cerda, San Miguel, Maipú, Lo Espejo, La Cisterna, El Bosque, Lo Barnechea, Vitacura, Las Condes, Providencia, La Reina, Ñuñoa, Peñalolen, Macul, San Joaquin, La Florida,
La Granja, San Ramón, La Pintana, Puente Alto, Padre Hurtado, San Bernardo.</b-card-text>
            <b-card-text> <code>Reparto $3.500</code>
Paine, Pirque, El Monte, Buin, Colina, Lampa, Peñaflor, Talagante, Calera de Tango, Chicureo, Curacaví, Til Til y María Pinto.</b-card-text>
            <b-card-text> <code>Reparto $4.000</code>
Viña del Mar, Valparaíso, Rancagua Machalí</b-card-text>
            <b-card-text>Demás localidades se realizan envío por pagar según lo coordinado de forma directa con cada cliente.</b-card-text>

          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-4 variant="secondary">¿Tengo que pagar algo adicional para recibir mis cartas?</b-button>
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>No, todo lo que pagas aparece al momento de hacer el check out. En donde aparece el valor total de tus cartas más los $2.500 de envío (RM). ¡Nada más!</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-5 variant="secondary">¿Me van a cobrar algún monto por la importación de las cartas?</b-button>
        </b-card-header>
        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>No, Best Tutor se encarga de todos los gastos de envío, internación y demás gastos que resulten de la operación. Así que el cliente puede estar tranquilo de que no tendrán cobros sorpresas.</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-6 variant="secondary">¿Qué día realizan las entregas?</b-button>
        </b-card-header>
        <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>Dependiendo el día que lleguen los pedidos y lugar de entrega existen diferentes rangos. En la región metropolitana no supera los dos días (si entregamos a repartidor el martes a más tardar llegaría el miércoles). Localidades lejanas las entregas son el fin de semana. (repartos de $3.500 y $4.000)</b-card-text>
            <b-card-text>Para el resto de Chile va a depender del método de envío que escoja el cliente.</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-7 variant="secondary">¿Se puede acordar la entrega en algún metro?</b-button>
        </b-card-header>
        <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>Por ahora no estamos realizando entregas de forma presencial, ya que estamos tratando de mantener un ambiente de trabajo 100% sanitizado y muy limpio, por este motivo no salimos a menos que sea estrictamente necesario.</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-8 variant="secondary">¿Cuánto se demoran en llegar los pedidos?</b-button>
        </b-card-header>
        <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>El tiempo es un poco relativo, pero por lo general alrededor de los días 15 de cada mes tendrían que recibir sus compras.</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-9 variant="secondary">¿Qué significa los estados de las cartas?</b-button>
        </b-card-header>
        <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>Las cartas pueden estar en diferente estado de preservación.</b-card-text>
            <b-card-text><code>PF:</code> Perfecta, las cartas están nuevas, recién salidas del sobre.</b-card-text>
            <b-card-text><code>BE:</code> Buen Estado, las cartas se han utilizado un poco en juego, casi sin nada de daño.</b-card-text>
            <b-card-text><code>MJ:</code> Muy Jugada, las cartas se han jugado harto, pueden tener marcas por desgaste del juego.</b-card-text>
            <b-card-text><code>ET:</code> Estropeada, las cartas tienen algún daño visual leve, de todos modos son cartas jugables.</b-card-text>
            <b-card-text>no tendrá cobros sorpresas.</b-card-text>

          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-10 variant="secondary">¿Hasta cuándo puedo realizar la compra?</b-button>
        </b-card-header>
        <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>Las compras las puedes realizar cualquier día, pero nosotros confirmamos los pedidos los días 5 de cada mes. Esto significa que, si compras algo el día 6 de algún mes, la compra no se confirmará hasta el día 5 del mes siguiente. Así que debes tener eso en mente al momento de estimar la fecha de entrega.</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-11 variant="secondary">¿Hay algún monto mínimo o máximo?</b-button>
        </b-card-header>
        <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>No hay monto mínimo o máximo, sólo tienes que tener en cuenta que siempre se añade el costo por concepto de envío a domicilio. (por ejemplo si compras una carta de 50 pesos tendrás que pagar los 2.500 de envío (RM) dando un total de 2.550)</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-12 variant="secondary">¿Qué pasa si al momento de la entrega no me encuentro en mi domicilio?</b-button>
        </b-card-header>
        <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>Es importante que en la dirección de entrega se encuentre alguien. De lo contrario la empresa encargada de la entrega cobrará envío adicional. Ahora, siempre puedes enviarnos un correo o escribirnos por WhatsApp para revisar algún caso especial, estamos siempre a tu servicio.</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>

export default {
  name: 'FAQ'
}
</script>
