import axios from 'axios'
import authHeader from './auth-header.service'

class MonedaService {
  async obtenerMoneda () {
    const url = 'monedas'
    return await axios.get(url, {
      baseURL: process.env.VUE_APP_URL_API_BESTTUTOR,
      headers: authHeader()
    })
  }

  async modificarMoneda (body) {
    const url = 'monedas'
    return await axios.put(url, body, {
      baseURL: process.env.VUE_APP_URL_API_BESTTUTOR,
      headers: authHeader()
    })
  }
}

export default new MonedaService()
