import axios from 'axios'
import authHeader from './auth-header.service'

class ProductoService {
  obtenerProductos (idPedido) {
    const url = 'pedidos/' + idPedido + '/productos'
    return axios.get(url, {
      baseURL: process.env.VUE_APP_URL_API_BESTTUTOR,
      headers: authHeader()
    })
  }
}

export default new ProductoService()
