<template>
  <div class="container-fluid mt-4">
    <div v-if="usuario.rolId === 1">
      <p v-if="errors.length">
        <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
        <ul>
          <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
        </ul>
      </p>
      <p>Valor Dolar: {{valorMoneda}}</p>
      <div class="form-group">
        <label for="moneda">Cambiar Valor Dolar</label>
        <input type="text" class="form-control" id="moneda" placeholder="Ingresar Valor Dolar" v-model="$v.moneda.$model">
        <button type="button" class="btn btn-primary mb-2" @click="modificarDolar">Modificar</button>
      </div>
    </div>
    <p>{{mensaje}}</p>
  </div>
</template>
<script>
import MonedaService from '../service/moneda.service'
import { required, maxLength, numeric } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
  name: 'Utilitarios',
  data () {
    return {
      valorMoneda: '',
      moneda: '',
      errors: [],
      mensaje: ''
    }
  },
  validations: {
    moneda: {
      required,
      maxLength: maxLength(11),
      numeric
    }
  },
  async beforeMount () {
    await MonedaService.obtenerMoneda()
      .then(
        response => {
          const optChile = { style: 'currency', currency: 'CLP' }
          const numFormatPeso = new Intl.NumberFormat('es-CL', optChile)
          this.valorMoneda = numFormatPeso.format(response.data.valorPesoChileno)
        }
      )
  },
  created () {
    if (this.loggedIn) {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapState({
      usuario: state => state.auth.usuario
    })
  },
  methods: {
    modificarDolar () {
      this.mensaje = ''
      this.errors = []
      this.$v.$touch()
      if (!this.$v.moneda.required) {
        this.errors.push('Debe ingresar un monto')
      } else if (!this.$v.moneda.maxLength) {
        this.errors.push('El largo commo maximo debe ser 11 numeros')
      } else if (!this.$v.moneda.numeric) {
        this.errors.push('Ingrese solo numeros')
      }

      if (this.errors.length) {
        return false
      } else {
        const body = { valorMoneda: parseInt(this.moneda) }
        MonedaService.modificarMoneda(body)
          .then(
            () => {
              this.valorMoneda = this.moneda
              this.mensaje = 'Se actualizo el valor del dolar con exito'
            }
          )
          .catch(
            () => {
              this.mensaje = 'Hubo problemas al actualizar el dolar'
            }
          )
      }
    }
  }
}
</script>
