<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import axios from 'axios'

export default {
  name: 'APP',
  methods: {
    ...mapMutations(['initCarroCache'])
  },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'Your Website'
    }
  },
  created () {
    axios.interceptors.request.use(function (config) {
      return config
    }, function (err) {
      return Promise.reject(err)
    })
    if (localStorage.carro) {
      this.initCarroCache(JSON.parse(localStorage.carro))
    }
  }
}
</script>

<style>
body { padding-top: 103px !important;
overflow-x: hidden; }
@media screen and (max-width: 991px) {
    body { padding-top: 104px !important; }
}
</style>
