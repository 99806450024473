<template>
  <div class="row text-center text-lg-left">
    <div v-for="(carta, index) in cartas"  v-bind:key="index" class="col-lg-2 col-md-4 col-6 mb-4">
      <div class="row">
        <div class="col-12">
          <div class="row justify-content-md-center">
            <img v-if="carta.imagen"
              class="card-img-top img-fluid carta"
              :src="carta.imagen"
              alt="Card image cap"
            />
          </div>
          <div class="col-12">
            <h6 class="card-title col-12 px-0" >
              {{ carta.nombre }}
            </h6>
            <p class="col-12 px-0" style="font-size: 12px;">
              {{ carta.edicion }}
            </p>
            <p class="col-12 px-0" style="font-size: 12px;">
              {{ carta.idioma }}
            </p>
            <div class="row fila-casilla" v-for="(tipo, index) in carta.tipos" v-bind:key="index">
              <div class="col-3 casilla"><span  v-b-tooltip.hover :title="tipo.condicionGlosa">{{ tipo.condicion }}</span></div>
              <div class="col-3 casilla" style="padding: 0 !important;">{{ tipo.precioFormat }} </div>
              <div class="col-3 casilla" style="font-weight: bold;">
                <span  v-b-tooltip.hover title="Nivel Stock">{{ tipo.rango }} </span>
              </div>
              <div class="col-3 casilla" >
                <CarroBoton :carta="carta" :tipo="tipo" :isActive="isActive" ></CarroBoton>
                  <!-- <b-icon v-if="isActive" @click="addProducto(carta, tipo)" icon="cart-plus-fill" aria-hidden="true" scale="2" ></b-icon>
                  <b-icon v-else @click="addProducto(carta, tipo)" icon="cart-plus" aria-hidden="true" scale="2" ></b-icon>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import CarroBoton from '@/components/CarroBoton.vue'

export default {
  name: 'Carta',
  components: {
    CarroBoton
  },
  data () {
    return {
      isActive: true
    }
  },
  computed: {
    ...mapState({
      cartas: state => state.cartas
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.casilla{
    font-size: 12px;
    cursor: pointer;
}
.fila-casilla{
    padding: 8px 0;
    border-bottom:1px solid;
}
.carta{
    width: 95%;
    height: auto;
}
</style>
