<template>
    <div>
    <Cabecera/>
    <div class="car" v-if="!modoCompra">
      <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000;"
      fade
      indicators
      img-width="1024"
      img-height="200"
    >
        <b-carousel-slide
          caption=""
          img-src="https://www.besttutor.cl/url-images/carro-1.jpg"
        ></b-carousel-slide>
        <b-carousel-slide
          caption=""
          img-src="https://www.besttutor.cl/url-images/carro-2.jpg"
        ></b-carousel-slide>
        <b-carousel-slide
          caption=""
          img-src="https://www.besttutor.cl/url-images/carro-3.jpg"
        ></b-carousel-slide>
      </b-carousel>
    </div>
    <div v-if="mostrarSpinner" class="text-center">
      <b-spinner style="width: 6rem; height: 6rem;" label="Large Spinner"></b-spinner>
    </div>
    <router-view/>
  </div>
</template>

<script>
import Cabecera from '@/components/Cabecera.vue'
import { mapState } from 'vuex'

export default {
  name: 'Store',
  components: {
    Cabecera
  },
  computed: {
    ...mapState({
      modoCompra: state => state.modoCompra,
      mostrarSpinner: state => state.mostrarSpinner
    })
  }
}
</script>
