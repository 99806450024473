<template>
  <div>
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4">¡Gracias por tu compra!</h1>
        <p class="lead">
          El siguiente paso para poder reservar el pedido es realizar una transferencia (mínimo el 50% de la compra). El plazo máximo para realizar este pago es el día y hora de cierre del pedido. Si este pago no está realizado al momento de cerrar el pedido, será anulado.
Una vez lleguen las cartas y esté confirmado el pago realizaremos el envío del producto a tu domicilio según la opción seleccionada.
La información de la cuenta para la transferencia es:
        </p>
        <ul class="list-group">
          <li class="list-group-item">Numero de Pedido: {{numeroPedido}}</li>
          <li class="list-group-item">Monto total de Compra: {{totalCompra}}</li>
          <li class="list-group-item">Nombre: {{cuentaBanco.nombre}}</li>
          <li class="list-group-item">Correo: {{cuentaBanco.correo}}</li>
          <li class="list-group-item">Banco: {{cuentaBanco.banco}}</li>
          <li class="list-group-item">Tipo: {{cuentaBanco.tipo}}</li>
          <li class="list-group-item">Número de Cuenta: {{cuentaBanco.numero}}</li>
          <li class="list-group-item">Rut: {{cuentaBanco.rut}}</li>
          <li class="list-group-item">Asunto: Indicar cual es el número de tu pedido</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'PasoFinalCompra',
  data () {
    return {
      cuentaBanco: {}
    }
  },
  methods: {
    async getCuentaBanco () {
      const url = '/cuentaBanco'
      try {
        const resp = await axios.get(url, {
          baseURL: process.env.VUE_APP_URL_API_BESTTUTOR
        })
        this.cuentaBanco = await resp.data
      } catch (error) {
        this.$router.push({ name: 'Error' })
      }
    }
  },
  async beforeMount () {
    await this.getCuentaBanco()
    this.$store.state.modoCompra = true
  },
  beforeDestroy () {
    this.$store.state.modoCompra = false
  },
  computed: {
    ...mapState({
      numeroPedido: state => state.carro.numeroPedido,
      totalCompra: state => state.carro.totalCompra
    })
  }
}
</script>
