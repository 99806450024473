import axios from 'axios'
import router from '../router/index.js'
var jwt = require('jsonwebtoken')

class AuthService {
  login (usuario) {
    return axios.post('/login', JSON.stringify(usuario), {
      baseURL: process.env.VUE_APP_URL_API_BESTTUTOR,
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(response => {
        let decodedJWT = {}
        try {
          decodedJWT = jwt.verify(response.data.accessToken, process.env.VUE_APP_JWT_KEY)
          decodedJWT.accessToken = response.data.accessToken
        } catch (err) {
          router.push({ name: 'Error' })
        }
        localStorage.setItem('usuario', JSON.stringify(decodedJWT))

        return decodedJWT
      })
  }

  logout () {
    localStorage.removeItem('usuario')
  }

  register (usuario) {
    return axios.post('/usuario', JSON.stringify(usuario), {
      baseURL: process.env.VUE_APP_URL_API_BESTTUTOR,
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
  }
}

export default new AuthService()
