import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index.js'
import { auth } from './auth.module'

Vue.use(Vuex)
const optChile = { style: 'currency', currency: 'CLP' }
const numFormatPeso = new Intl.NumberFormat('es-CL', optChile)

const getDefaultState = () => {
  return {
    tipoBusqueda: 1,
    opcionBusqueda: [
      { value: 1, text: 'Magic Singles' },
      { value: 2, text: 'Magic Tokens' }
    ],
    textoBuscarInput: '',
    cartas: [],
    modoCompra: false,
    esRegistroUsuario: false,
    busquedaFallida: true,
    textoBusquedaFallida: '',
    mostrarSpinner: false,
    paginador: {
      paginas: 0,
      elementosPorPaginas: 12,
      totalElementos: 0,
      puntero: 0,
      textoBusqueda: '',
      start: function () {
        return this.puntero * this.elementosPorPaginas
      }
    },
    carro: getCarro(),
    carroActivo: false
  }
}

const getCarro = () => {
  return {
    cantidadProductos: 0,
    productos: new Map(),
    listProductos: [],
    precioTotal: 0,
    precioTotalFormat: '',
    envio: {
      id: -1,
      precio: 0
    },
    numeroPedido: 0,
    totalCompra: ''
  }
}

export const store = new Vuex.Store({
  modules: {
    auth
  },
  state: getDefaultState(),
  mutations: {
    setPaginador (state) {
      if (state.textoBuscarInput.localeCompare('') !== 0) {
        state.paginador.textoBusqueda = state.textoBuscarInput
        state.paginador.puntero = 0
        state.paginador.totalElementos = 0
        state.paginador.paginas = 0
      }
    },
    setPuntero (state, nuevaPosicion) {
      state.paginador.puntero = nuevaPosicion
    },
    setTextoBuscar (state, texto) {
      state.textoBuscarInput = texto
    },
    setTipoBusqueda (state, tipoBusqueda) {
      state.tipoBusqueda = tipoBusqueda
    },
    llenarCartas (state, busqueda) {
      if (busqueda.cartas.length > 0) {
        state.cartas = busqueda.cartas
        state.paginador.totalElementos = busqueda.numTotalCartas
        state.paginador.paginas = busqueda.cantidadPaginas
        state.busquedaFallida = false
        state.textoBusquedaFallida = ''
        window.scrollTo(0, 0)
      } else {
        state.cartas = []
        state.busquedaFallida = true
        state.textoBusquedaFallida = 'No se encontraron cartas con la busqueda: ' + state.textoBuscarInput
        state.textoBuscarInput = ''
      }
    },
    setCarro (state, item) {
      if (state.carro.productos.has(item.id)) {
        const itemCarro = state.carro.productos.get(item.id)
        state.carro.precioTotal = state.carro.precioTotal + item.precio
        state.carro.precioTotalFormat = numFormatPeso.format(state.carro.precioTotal)
        item.cantidad = item.cantidad + itemCarro.cantidad
        item.precio = item.precio + itemCarro.precio
        item.precioFormat = numFormatPeso.format(item.precio)
        state.carro.productos.set(item.id, item)
        ++state.carro.cantidadProductos
      } else {
        item.precioFormat = numFormatPeso.format(item.precio)
        state.carro.productos.set(item.id, item)
        state.carro.precioTotal = state.carro.precioTotal + item.precio
        state.carro.precioTotalFormat = numFormatPeso.format(state.carro.precioTotal)
        ++state.carro.cantidadProductos
      }
      state.carro.listProductos = []
      state.carro.productos.forEach(function (value, key) {
        state.carro.listProductos.push(value)
      })
      localStorage.carro = JSON.stringify(state.carro)
    },
    removerProducto (state, id) {
      const producto = state.carro.productos.get(id)
      state.carro.cantidadProductos = parseInt(state.carro.cantidadProductos) - parseInt(producto.cantidad)
      state.carro.precioTotal = parseInt(state.carro.precioTotal) - parseInt(producto.precio)
      state.carro.precioTotalFormat = numFormatPeso.format(state.carro.precioTotal)
      state.carro.productos.delete(id)
      state.carro.listProductos = []
      state.carro.productos.forEach(function (value, key) {
        state.carro.listProductos.push(value)
      })
    },
    sumarProducto (state, id) {
      const producto = state.carro.productos.get(id)
      state.carro.cantidadProductos = parseInt(state.carro.cantidadProductos) - parseInt(producto.cantidad)
      state.carro.precioTotal = parseInt(state.carro.precioTotal) - parseInt(producto.precio)
      state.carro.precioTotalFormat = numFormatPeso.format(state.carro.precioTotal)
      state.carro.productos.delete(id)
      state.carro.listProductos = []
      state.carro.productos.forEach(function (value, key) {
        state.carro.listProductos.push(value)
      })
    },
    setEnvio (state, envio) {
      state.carro.precioTotalFormat = ''
      if (state.carro.envio && state.carro.envio !== 'null' && envio.id > 0) {
        state.carro.precioTotal = parseInt(state.carro.precioTotal) - parseInt(state.carro.envio.precio)
        state.carro.precioTotal = parseInt(state.carro.precioTotal) + parseInt(envio.precio)
        state.carro.precioTotalFormat = numFormatPeso.format(state.carro.precioTotal)
        state.carro.envio = envio
      } else if (envio.id === -1) {
        state.carro.precioTotal = parseInt(state.carro.precioTotal) - parseInt(state.carro.envio.precio)
        state.carro.precioTotalFormat = numFormatPeso.format(state.carro.precioTotal)
        state.carro.envio = envio
      } else {
        state.carro.precioTotal = parseInt(state.carro.precioTotal) + parseInt(envio.precio)
        state.carro.precioTotalFormat = numFormatPeso.format(state.carro.precioTotal)
        state.carro.envio = envio
      }
      // localStorage.clear()
      localStorage.carro = JSON.stringify(state.carro)
    },
    comprar (state, usuario) {
      // TODO REVISAR
      // state.usuario = usuario
      const request = {}
      request.usuario = usuario
      request.usuario.idRol = 2
      request.usuario.password = null
      request.pedido = {}
      request.pedido.idTipoEnvio = state.carro.envio.id
      request.pedido.total = state.carro.precioTotal
      const direccion = usuario.calle + ' ' + usuario.numero
      request.pedido.direccion = direccion
      usuario.direccion = direccion
      if (usuario.departamento) {
        request.pedido.direccion = direccion + ' ' + usuario.departamento
        usuario.direccion = direccion + ' ' + usuario.departamento
      }
      request.pedido.comuna = usuario.comuna
      request.pedido.region = usuario.region
      request.pedido.referencia = usuario.referencia
      request.pedido.rut = usuario.rut
      request.productos = []

      state.carro.productos.forEach(function (item, key) {
        const producto = {}
        producto.nombre = item.nombre
        producto.edicion = item.edicion
        producto.idioma = item.idioma
        producto.urlImagen = item.imagen
        producto.idCondicion = item.idCondicion
        producto.cantidad = item.cantidad
        producto.precio = item.precio
        producto.idCategoria = 1
        request.productos.push(producto)
      })

      try {
        axios.post('/comprar', JSON.stringify(request), {
          baseURL: process.env.VUE_APP_URL_API_BESTTUTOR,
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        })
          .then((resp) => {
            const guardarTotal = state.carro.precioTotalFormat
            // Object.assign(state, getDefaultState())
            state.carro = getCarro()
            state.carro.numeroPedido = resp.data.numeroPedido
            state.carro.totalCompra = guardarTotal
            localStorage.carro = ''
            router.push({ name: 'PasoFinalCompra' })
          })
          .catch(() => {
            router.push({ name: 'Error' })
          })
      } catch (error) {
        router.push({ name: 'Error' })
      }
    },
    initCarroCache (state, carroCache) {
      state.carro.cantidadProductos = carroCache.cantidadProductos
      state.carro.listProductos = carroCache.listProductos
      state.carro.precioTotal = carroCache.precioTotal
      state.carro.precioTotalFormat = carroCache.precioTotalFormat
      state.carro.envio = carroCache.envio
      for (const producto of carroCache.listProductos) {
        state.carro.productos.set(producto.id, producto)
      }
    },
    carroEsActivo (state) {
      const url = '/muestraCarrito'
      axios.get(url, {
        baseURL: process.env.VUE_APP_URL_API_BESTTUTOR
      }).then(resp => {
        console.log('el carro esta activo ' + JSON.stringify(resp.data))
        state.carroActivo = resp.data.valor === '1'
      })
    }
  },
  actions: {
    obtenerCartas: async function ({ commit, state }) {
      const TOPE_CARTA_PAGINA = 3000
      let url = ''
      if (state.tipoBusqueda === 1) {
        url = '/cartas/singles/find'
      } else if (state.tipoBusqueda === 2) {
        url = '/cartas/tokens/find'
      }
      url = url + '?nombre=' + state.paginador.textoBusqueda + '&row=' + TOPE_CARTA_PAGINA + '&start=' + state.paginador.start()
      state.paginador.textoBusqueda = ''
      try {
        const resp = await axios.get(url, {
          baseURL: process.env.VUE_APP_URL_API_BESTTUTOR
        })
        const busqueda = await resp.data
        commit('llenarCartas', busqueda)
      } catch (error) {
        router.push({ name: 'Error' })
      }
    },
    removerPorId ({ commit }, id) {
      commit('removerProducto', id)
    },
    sumarProductoPorId ({ commit, state }, id) {
      const producto = state.carro.productos.get(id)
      const precioUnidadCarta = parseInt(producto.precio / producto.cantidad)
      producto.precio = producto.precio + precioUnidadCarta
      producto.precioFormat = numFormatPeso.format(producto.precio)
      state.carro.precioTotal = parseInt(state.carro.precioTotal) + precioUnidadCarta
      state.carro.precioTotalFormat = numFormatPeso.format(state.carro.precioTotal)
      state.carro.cantidadProductos = parseInt(state.carro.cantidadProductos) + 1
      producto.cantidad = producto.cantidad + 1
      localStorage.carro = JSON.stringify(state.carro)
    },
    restarProductoPorId ({ commit, state }, id) {
      const producto = state.carro.productos.get(id)
      if (parseInt(producto.cantidad) < 2) {
        state.carro.productos.delete(id)
        state.carro.listProductos = []
        state.carro.productos.forEach(function (value, key) {
          state.carro.listProductos.push(value)
        })
      }
      const precioUnidadCarta = parseInt(producto.precio / producto.cantidad)
      producto.precio = producto.precio - precioUnidadCarta
      producto.precioFormat = numFormatPeso.format(producto.precio)
      state.carro.precioTotal = parseInt(state.carro.precioTotal) - precioUnidadCarta
      state.carro.precioTotalFormat = numFormatPeso.format(state.carro.precioTotal)
      state.carro.cantidadProductos = parseInt(state.carro.cantidadProductos) - 1
      producto.cantidad = producto.cantidad - 1
      localStorage.carro = JSON.stringify(state.carro)
    }
  }
})
