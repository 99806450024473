<template>
  <div class="container-fluid mt-4">
    <div v-if="usuario.rolId === 1 && verTablaPedido">
      <div class="form-group">
        <label for="email">Buscar por Email</label>
        <input type="email" class="form-control" id="email" placeholder="Ingresar email" v-model="findEmail">
        <button type="button" class="btn btn-primary mb-2" @click="buscarPorEmail">Buscar</button>
      </div>
    </div>
    <div v-if="verTablaPedido" id="tabla-pedidos">
      <b-pagination
        v-model="currentPage"
        :total-rows="rowsPedidos"
        :per-page="perPage"
        aria-controls="pedidos"
      ></b-pagination>
      <b-table
        striped hover responsive
        id="pedidos"
        :items="pedidos"
        :fields="fieldsPedidos"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(detalle)="data">
          <a href="#" @click="verDetalle(data)">Ver Detalle</a>
        </template>
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="rowsPedidos"
        :per-page="perPage"
        aria-controls="pedidos"
      ></b-pagination>
    </div>
    <div v-if="verTablaDetalle" id="tabla-productos">
      <p>Numero Pedido: {{numeroPedido}}</p>
      <p v-if="usuario.rolId === 1">Email: {{email}}</p>
      <p v-if="usuario.rolId === 1">Nombre: {{nombreCompleto}}</p>
      <p v-if="usuario.rolId === 1">Telefono: {{telefono}}</p>
      <p>Direccion: {{direccionCompleta}}</p>
      <p>Referencia: {{referencia}}</p>
      <p>Rut: {{rut}}</p>
      <p>Costos de Envio: {{costoEnvio}}</p>
      <p>Total Pedido: {{totalPedido}}</p>
      <button @click="verPedidos" type="button" class="btn btn-primary mb-2">Ver Pedidos</button>
      <b-pagination
        v-model="currentPageProductos"
        :total-rows="rowsProductos"
        :per-page="perPageProductos"
        aria-controls="productos"
      ></b-pagination>
      <b-table
        striped hover responsive
        id="productos"
        :items="productos"
        :per-page="perPageProductos"
        :current-page="currentPageProductos"
        small
      >
        <template #cell(imagen)="data">
          <img :src="data.value" style="width: 50px; max-width: 100%;">
        </template>
        <template #cell(urlAbugames)="data">
          <a :href="data.value" target="_blank">Link</a>
        </template>
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>

      <b-pagination
        v-model="currentPageProductos"
        :total-rows="rowsProductos"
        :per-page="perPageProductos"
        aria-controls="productos"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import PedidoService from '../service/pedido.service'
import ProductoService from '../service/producto.service'
import { mapState } from 'vuex'

export default {
  name: 'Pedidos',
  data () {
    return {
      perPage: 10,
      currentPage: 1,
      perPageProductos: 10,
      currentPageProductos: 1,
      fieldsPedidos: [],
      pedidos: [],
      productos: [],
      verTablaDetalle: false,
      verTablaPedido: true,
      numeroPedido: '',
      totalPedido: '',
      costoEnvio: '',
      direccionCompleta: '',
      email: '',
      rut: '',
      referencia: '',
      nombreCompleto: '',
      telefono: '',
      findEmail: ''
    }
  },
  mounted () {
    PedidoService.obtenerPedidos()
      .then(
        response => {
          this.pedidos = response.data
          for (const pedido in this.pedidos[0]) {
            if (pedido !== 'direccion' && pedido !== 'comuna' && pedido !== 'region' && pedido !== 'rut' && pedido !== 'referencia') {
              const item = {}
              item.key = pedido
              item.sortable = true
              this.fieldsPedidos.push(item)
            }
          }
          const item = {}
          item.key = 'detalle'
          this.fieldsPedidos.push(item)
        }
      )
  },
  created () {
    if (this.loggedIn) {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapState({
      usuario: state => state.auth.usuario
    }),
    rowsPedidos () {
      return this.pedidos.length
    },
    rowsProductos () {
      return this.productos.length
    }
  },
  methods: {
    verDetalle (pedido) {
      const idPedido = pedido.item.numeroPedido
      ProductoService.obtenerProductos(idPedido)
        .then(
          response => {
            this.productos = response.data
            this.numeroPedido = pedido.item.numeroPedido
            this.costoEnvio = pedido.item.costoEnvio
            this.totalPedido = pedido.item.total
            this.direccionCompleta = pedido.item.direccion + ', ' + pedido.item.comuna + ', ' + pedido.item.region
            this.email = pedido.item.usuario
            this.rut = pedido.item.rut
            this.referencia = pedido.item.referencia
            this.nombreCompleto = pedido.item.nombreUsuario
            this.telefono = pedido.item.telefono
            this.verTablaPedido = false
            this.verTablaDetalle = true
            this.$root.$emit('bv::refresh::table', 'productos')
          }
        )
    },
    verPedidos () {
      this.productos = []
      this.numeroPedido = ''
      this.costoEnvio = ''
      this.totalPedido = ''
      this.direccionCompleta = ''
      this.verTablaPedido = true
      this.verTablaDetalle = false
    },
    buscarPorEmail () {
      this.pedidos = []
      PedidoService.obtenerPedidosPorEmail(this.findEmail)
        .then(
          response => {
            this.pedidos = response.data
            for (const pedido in this.pedidos[0]) {
              if (pedido !== 'direccion' && pedido !== 'comuna' && pedido !== 'region') {
                const item = {}
                item.key = pedido
                item.sortable = true
                this.fieldsPedidos.push(item)
              }
            }
            const item = {}
            item.key = 'detalle'
            this.fieldsPedidos.push(item)
            this.$root.$emit('bv::refresh::table', 'pedidos')
          }
        )
    }
  }
}
</script>

<style scoped>
#tabla-productos p {
  font-weight: bold;
}
</style>
