<template>
  <div class="container-fluid mt-4">
    <!-- <Paginador />-->
    <Carta />
    <!-- <Paginador />-->
    <div v-if="cartas.length == 0 && busquedaFallida">
      <p style="font-weight: bold;">{{textoBusquedaFallida}}</p>
    </div>
    <div v-if="cartas.length == 0" class="full-width-image">
      <img alt="instrucciones" src="https://www.besttutor.cl/url-images/instrucciones.jpg" />
    </div>
  </div>
</template>

<style scoped>
.full-width-image {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
}

.full-width-image img {
  width: 100%;
}
</style>
<script>
// @ is an alias to /src
import Carta from '@/components/Carta.vue'
// import Paginador from '@/components/Paginador.vue'
import { store } from '@/store/storeBuscador'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  store,
  computed: {
    ...mapState({
      busquedaFallida: state => state.busquedaFallida,
      textoBusquedaFallida: state => state.textoBusquedaFallida,
      cartas: state => state.cartas
    })
  },
  components: {
    Carta
  }
}
</script>
